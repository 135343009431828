import React, { useState } from 'react';
//import { currencies } from '../User/Profile/Currencies';
import {useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AppSetup.css'
import { Spinner, ToggleButton } from 'react-bootstrap';
import { url } from './Redux/Api';
import { FaTrash } from 'react-icons/fa';

const AppSetting = () => {
    const app = useSelector(state=>state.apps.items)
    const user = useSelector(state=>state.auth.user);
    
    const[deleteAppUsersSuccess, setDeleteAppUsersSuccess] = useState('')
    const[deleteAppShopsSuccess, setDeleteAppShopsSuccess] = useState('')
    const[userRegAppSuccess, setUserRegAppSuccess] = useState('')
    const[shopRegAppSuccess, setShopRegAppSuccess] = useState('')
    const[createAppSuccess, setCreateAppSuccess] = useState('')
    const[appUpdateAppSuccess, setAppUpdateAppSuccess] = useState('')

    const[createAppError, setCreateAppError] = useState('')
    const[appUserRegError, setAppUserRegError] = useState('')
    const[appShopRegError, setAppShopRegError] = useState('')
    const[appDeleteUsersError, setAppDeleteUsersError] = useState('')
    const[appDeleteShopsError, setAppDeleteShopsError] = useState('')
    const[appUpdateAppError, setAppUpdateAppError] = useState('')
    
    const[loadinAppUserReg, setLoadinAppUserReg] = useState(false)
    const[loadinAppShopReg, setLoadinAppShopReg] = useState(false)
    const[deleteAppUsersLoading, setDeleteAppUsersLoading] = useState(false)
    const[deleteAppShopsLoading, setDeleteAppShopsLoading] = useState(false)
    const[loadingAppUpdate, setLoadingAppUpdate] = useState(false) 
    const[loadingCreateApp, setLoadingCreateApp] = useState(false) 

    const[personalMin, setPersonalMin] = useState(0)
    const[grooveMin, setGrooveMin] = useState(0)
    const[minTime, setMinTime] = useState(0)
    const[help, setHelp] = useState('')
    const[grooveTime, setGrooveTime] = useState(0)
    const[personalRate, setPersonalRate] = useState(0)
    const[appMaxLevel, setAppMaxLevel] = useState(0)
    const[savingsTime, setSavingsTime] = useState(0)
    const[grooveRate, setGrooveRate] = useState(0)
    const[referralBonus, setReferralBonus] = useState(0)
    const[groovePayAllSpeed, setGroovePayAllSpeed] = useState(0)
    const[grooveFirstPaySpeed, setGrooveFirstPaySpeed] = useState(0)
    const[currency, setCurrency] = useState('')
    const[referralExpiration, setReferralExpiration] = useState(0)
    const[increment, setIncrement] = useState(0)
    const[baseSalary, setBaseSalary] = useState(0)
    const[salaryTarget, setSalaryTarget] = useState(0)
    const[withdrawable, setWithdrawable] = useState(0)
    
    
    const deleteShops = ()=>{
        setDeleteAppShopsLoading(true)
            async function fetchData(){
                await fetch(`${url}/delete-app-shops`,{
                    method:'delete',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    },body:JSON.stringify({user})
                }).then(resp=>{
                    if(resp.resp){
                        setDeleteAppShopsSuccess(resp.resp)
                        setDeleteAppShopsLoading(false)
                    }else if(resp.err){
                        setAppDeleteShopsError(resp.err)
                        setDeleteAppShopsLoading(false)
                    }
                }).catch(err=>{
                    if(err){
                        setAppDeleteShopsError(err.message)
                        setDeleteAppShopsLoading(false)
                    }
                })
            }
            fetchData()
    }

    const deleteUsers = ()=>{
        setDeleteAppUsersLoading(true)
            async function fetchData(){
                await fetch(`${url}/delete-app-users`,{
                    method:'delete',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    }
                }).then(resp=>{
                    if(resp.resp){
                        setDeleteAppUsersSuccess(resp.resp)
                        setDeleteAppUsersLoading(false)
                    }else if(resp.err){
                        setAppDeleteUsersError(resp.err)
                        setDeleteAppUsersLoading(false)
                    }
                })
            }
            fetchData()
    }


    const disableShopsReg = ()=>{
        setLoadinAppShopReg(true)
            async function fetchData(){
                await fetch(`${url}/shop-enlisting-app-setting`,{
                    method:'put',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    }
                }).then(resp=>{
                    if(resp.resp){
                        setShopRegAppSuccess(resp.resp)
                        setLoadinAppShopReg(false)
                    }else if(resp.err){
                        setAppShopRegError(resp.err)
                        setLoadinAppShopReg(false)
                    }
                })
            }
            fetchData()
    }
    
    const disableUserReg = ()=>{
        setLoadinAppUserReg(true)
            async function fetchData(){
                await fetch(`${url}/user-registration-app-setting`,{
                    method:'put',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    }
                }).then(resp=>{
                    if(resp.resp){
                        setUserRegAppSuccess(resp.resp)
                        setLoadinAppUserReg(false)
                    }else if(resp.err){
                        setAppUserRegError(resp.err)
                        setLoadinAppUserReg(false)
                    }
                })
            }
            fetchData()
    }

    const handleSalaryApproval = ()=>{
        setLoadingCreateApp(true)
            async function fetchData(){
                await fetch(`${url}/salary-approval`,{
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    }
                }).then(resp=>{
                    if(resp.resp){
                        toast.success(`${resp.resp.length} workers were paid`, {position: toast.POSITION.TOP_RIGHT});
                        setAppUpdateAppSuccess(resp.resp)
                        setLoadingCreateApp(false)
                    }else if(resp.err){
                        toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT});
                        setAppUpdateAppError(resp.err)
                        setLoadingCreateApp(false)
                    }
                })
                .catch(err=>{
                    setLoadingCreateApp(false)
                    return toast.error(err.message, {position: toast.POSITION.TOP_RIGHT});
                })
            }
           return fetchData()
        }
    const handleAppUpdate = (e)=>{
        e.preventDefault()
        setLoadingAppUpdate(true)
            async function fetchData(){
                await fetch(`${url}/update-app`,{
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    },body:JSON.stringify({
                        personalMin,withdrawable,
	grooveMin,
	minTime,
	help,
	grooveTime,
	personalRate,
	appMaxLevel,
	savingsTime,
	grooveRate,
	referralBonus,
	groovePayAllSpeed,
	grooveFirstPaySpeed,
	currency,
	referralExpiration,
	increment,baseSalary,salaryTarget
                    })
                }).then(resp=>{
                    if(resp.resp){
                        setAppUpdateAppSuccess(resp.resp)
                        setLoadingAppUpdate(false)
                        return toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT});
                    }else if(resp.err){
                        setAppUpdateAppError(resp.err)
                        setLoadingAppUpdate(false)
                        return toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT});
                    }
                }).catch(err=>{
                    setLoadingAppUpdate(false)
                    return toast.error(err.message, {position: toast.POSITION.TOP_RIGHT});
                })
            }
           return fetchData()
        }

const[name, setName] = useState('')
const[bankName, setBankName] = useState('')
const[accountName, setAccountName] = useState('')
const[accountNumber, setaccountNumber] = useState(0)

    const createAppSetting = (e)=>{
        e.preventDefault()
        setLoadingCreateApp(true)
                async function fetchData(){
await  fetch(`${url}/create-app-setting`,{
    method:'post',
    headers:{
        'Content-Type':'application/json',
        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
    },body:JSON.stringify({
        name,bankName,accountName,accountNumber
    })
}).then(resp=>{
    if(resp.resp){
        setLoadingCreateApp(false)
        return toast.success(resp.resp,{position:toast.POSITION.TOP_RIGHT})
    }else if(resp.err){
        setCreateAppError(resp.err)
        setLoadingCreateApp(false)
        return toast.success(resp.err,{position:toast.POSITION.TOP_RIGHT})
    }
}).catch(err=> {setLoadingCreateApp(false);return toast.success(err.message,{position:toast.POSITION.TOP_RIGHT});})
                }
                return fetchData()
            }
    return (
        <div className='app-main'>
        <ToastContainer />
            <div className='shop-settings'>
                {
                    app?.salaryPaymentRequests?.filter(filt=>filt.status==='pending').length>0&&<button className='update-btn' onClick={handleSalaryApproval}>
                        {
                            setLoadingAppUpdate?<Spinner />:`Approve ${app?.salaryPaymentRequests?.filter(filt=>filt.status==='pending').length} Salaries`
                        }
                        </button>
                }
                {
                    createAppSuccess!==''?<p>{createAppSuccess}</p>:createAppError!==''?<p>{createAppSuccess}</p>:<p className='setting-title'>Initiate App Settings</p>
                }
                {
                    <form className='bank-form' onSubmit={createAppSetting} >
                        <input type='text' placeholder='App name' onChange={e=>setName(e.target.value)} className='bank-input'/>
                        <input type='text' placeholder='Bank name' onChange={e=>setBankName(e.target.value)} className='bank-input'/>
                        <input type='text' placeholder='Account name' onChange={e=>setAccountName(e.target.value)} className='bank-input'/>
                        <input type='number' placeholder='Bank number' onChange={e=>setaccountNumber(e.target.value)} className='bank-input'/>
                        {loadingCreateApp===true?<Spinner className='app-setting-spinner' />:<button type='submit' className='create-setting'>Create Setting</button>}
                    </form>
                }
           {
                    appUpdateAppSuccess!==''?<p>{appUpdateAppSuccess}</p>:appUpdateAppError!==''?<p>{appUpdateAppError}</p>:<p className='setting-title'>Update Premium Cost</p>
                }
                <form className='app-settings-form' onSubmit={handleAppUpdate}>
                    <input type='number' step='any' min='0' placeholder ='Personal Minimun Investment' onChange={(e)=>setPersonalMin(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Goove Minimun Investment' onChange={(e)=>setGrooveMin(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Personal Minimum Time' onChange={(e)=>setMinTime(e.target.value)} className='update-input' />
                    <input type='email' placeholder ='Help email addres' onChange={(e)=>setHelp(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Groove Minimum Time' onChange={(e)=>setGrooveTime(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Personal Interest Rate' onChange={(e)=>setPersonalRate(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Orbits Maximum Level' onChange={(e)=>setAppMaxLevel(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Savings Time' onChange={(e)=>setSavingsTime(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Groove Interest Rate Minimum Time' onChange={(e)=>setGrooveRate(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Referral Bonus Rate' onChange={(e)=>setReferralBonus(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Interest Rate For Paying Whole Groove Target Once' onChange={(e)=>setGroovePayAllSpeed(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Interest Rate For Being the first payer' onChange={(e)=>setGrooveFirstPaySpeed(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Referral Expiration' onChange={(e)=>setReferralExpiration(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Increment rate to the next orbit' onChange={(e)=>setIncrement(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Base salary' onChange={(e)=>setBaseSalary(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Salary target' onChange={(e)=>setSalaryTarget(e.target.value)} className='update-input' />
                    <input type='number' step='any' min='0' placeholder ='Withdrwable' onChange={(e)=>setWithdrawable(e.target.value)} className='update-input' />
                    
                    {/* <select onChange={e=>setCurrency(e.target.value)} id='currency-select'>
                    <option id='currency-option'>Select Currency</option>
                            {
                                currencies.map((item,index)=>{
                                    return(
                                        <option id='currency-option' key={index} value={item.symbol}>{item.symbol}&nbsp;{item.name}</option>
                                    )
                                })
                            }
                    </select> */}
                {
                    loadingAppUpdate?<Spinner className='setting-spinner' />:<button type='submit' className='update-btn'>Update Subscription</button>  
                }
                </form>
            </div> 

           <div className='user-settings'>
                <p className='settings-title'>User</p>

           <form className='app-settings-form-togglers'>


           {
                userRegAppSuccess!==''?<p>{userRegAppSuccess}</p>:appUserRegError?<p>{appUserRegError}</p>:<span>Suspend User Registration</span>
                }
                {
                    loadinAppUserReg?<Spinner className='setting-spinner' />:<ToggleButton className='toggle-off' onClick={disableUserReg}/>
                }
            </form>      
            <form className='app-settings-form-togglers'>
            {
                deleteAppUsersSuccess!==''?<p>{deleteAppUsersSuccess}</p>:appDeleteUsersError?<p>{appDeleteUsersError}</p>:<p>{<span>Delete All Users</span>}</p>
            }
                
                {
                    deleteAppUsersLoading?<Spinner className='setting-spinner'/>:<FaTrash onClick={deleteUsers} className='trash-can'/>
                }
            </form>   
            </div>     
            <div className='shop-settings'>
            <p className='settings-title'>Shop</p>
            <form className='app-settings-form-togglers'>

            {
                shopRegAppSuccess!==''?<p>{shopRegAppSuccess}</p>:appShopRegError?<p>{appShopRegError}</p>:<span>Suspend Shop Enlisting</span>
                }
                
                {
                    loadinAppShopReg?<Spinner className='setting-spinner' />:<ToggleButton className='toggle-off' onClick={disableShopsReg}/>
                }
            </form>    
            <form className='app-settings-form-togglers'>
{
    deleteAppShopsSuccess!==''?<p>{deleteAppShopsSuccess}</p>:appDeleteShopsError!==''?<p>{appDeleteShopsError}</p>:<p>{<span>Delete All Shops</span>}</p>
}
                {
                    deleteAppShopsLoading?<Spinner className='setting-spinner'/>:<FaTrash onClick={deleteShops} className='trash-can'/>
                }
            </form>            
            </div>    
        </div>
    );
};

export default AppSetting;