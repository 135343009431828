import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardText, CardTitle, CloseButton, Col, Container, Form, FormControl, Row, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { css } from '@emotion/css';
import ScrollToBottom from 'react-scroll-to-bottom';
import { socketCert, socketKey, url } from '../../../Redux/Api';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import poster from '../LoadingImg.jpg'
// import 'codemirror/mode/xml/xml'
// import 'codemirror/mode/javascript/javascript'
// import 'codemirror/mode/css/css'
import './Classroom.css'
import { FaLock, FaLockOpen, FaShower, FaVideo, FaVideoSlash } from 'react-icons/fa';

const { io } = require("socket.io-client");

const ROOT_CSS = css({
  height: 600,
  width: '100%'
});
var socket,selectedChatCompare;

const Classroom = props => {
    const{lessonId,title} = useParams();
    const course = useSelector(state=>state.course.item);
    const lessons = useSelector(state=>state.lessons.items);
    const spaces = useSelector(state=>state.spaces.items);
    const user = useSelector(state=>state.auth.user);
    const users = useSelector(state=>state.users.items);
    const[loading, setLoading] = useState(false);
    const[newState, setNewState] = useState({status:'',data:''});
    const[lesson, setLesson] = useState(null);
const[messages, setMessages] = useState([]);
const[receivedMessages, setReceivedMessages] = useState([]);
const[videoOn, setVideoOn] = useState(false);
const[showMe, setShowMe] = useState(false);
const[currentMessage,setCurrentMessage] = useState('');//localStorage.getItem('received message')?JSON.parse(localStorage.getItem('received message')).message.message.text:'');
const[currentNote,setCurrentNote] = useState('');//localStorage.getItem('received message')?JSON.parse(localStorage.getItem('received message')).message.message.note:'');
const[space, setSpace] = useState({});

useEffect(()=>{
    if(spaces){
        let a = spaces?.filter(filt=>filt?._id?.toString()===lesson?.spaceId?.toString());
    setSpace(a[0]);
    }
},[lesson])

var welcome = `Welcome to ${course?.title}`;
const[text, setText] = useState('');
const[note, setNote] = useState('');
const[writerAuth, setWriterAuth] = useState('');
const[writerAuthSuggesttions, setWriterAuthSuggesttions] = useState([]);

const[participants, setParticipants] = useState([]);
const handleWriterAuth = ()=>{
    setLoading(true);
    setWriterAuthSuggesttions([])
    async function fetchData(){
        await fetch(`${url}/writer-auth`,{
            mode:'cors',
            method:'put',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({user,
              writerAuth,lessonId
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setLoading(false)
                setNewState({status:'success',data:resp.resp})
                window.location.reload()
              }else if(resp.err){
                setLoading(false);
                setWriterAuth("")
                return setNewState({status:'failed',data:resp.err})
            }else{
                setLoading(false)
                setWriterAuth("")
                return setNewState({status:'failed',data:'Something went wrong.'})
            }
        }).catch(err=>{setNewState({status:'failed',data:err.message});setWriterAuth("");setLoading(false)})
    }
    fetchData();
}

const[connected, setConnected] = useState(false);
useEffect(()=>{
    socket = io(url,{
        reconnectionDelayMax:10000,
        cert:socketCert,
        key:socketKey,
        secure:true,
        path:"/socket.io",
        reconnection:true,
        transports:['websocket','polling'],
        reconnectionAttempts:5
    })
    socket.emit('connected',{user:user,roomId:lessonId,welcome})
    socket.emit('disconnected',{user:user?._id,lessonId})//Complete this using disconnection's documentation
    socket.emit('join room',lessonId)
    socket.on('participants',(participants)=>{
        setParticipants(participants)
    })
    socket.on('joined',(a)=>{
        alert(a.toString())
        if(a===lessonId){
        return setConnected(true)
        }else{
            return setConnected(false)
        }
    })
},[user]);

useEffect(()=>{    
    socket.emit('message',{sender:user,lessonId,message:{text,note},time:new Date().getTime()});
    setMessages([...messages,{sender:user,lessonId,message:{text,note},time:new Date().getTime()}]);
    // setText('');
    // setNote('');
},[text,note]);

//useEffect(()=>{
    // const timer = setInterval(() => {
    //     async function fetchData(){
    //         await fetch(`${url}/lesson-conversation`,{
    //             mode:'cors',
    //             method:'put',
    //             headers:{
    //                 'Content-Type':'application/json',
    //                 'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
    //             },body:JSON.stringify({user,
    //               lessonId,message:{user,note,text,time:new Date().getTime()}
    //             })
    //         }).then(res=>res.json())
    //         .then(resp=>{
    //             if(resp.resp){
    //                 setLoading(false)
    //                 setNewState({status:'success',data:resp.resp})
    //               }else if(resp.err){
    //                 setLoading(false)
    //                 return setNewState({status:'failed',data:resp.err})
    //             }else{
    //                 setLoading(false)
    //                 return setNewState({status:'failed',data:'Something went wrong.'})
    //             }
    //         }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
    //     }
    //     fetchData();
    // }, 3000);
    // return clearInterval(timer);
//},[text,note]);
const[count, setCount] = useState(0);

function sendFunc(){
    async function fetchData(){
        console.log('blured',note)
        await fetch(`${url}/lesson-conversation`,{
            mode:'cors',
            method:'put',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({user,
              lessonId,message:{user,note,text,time:new Date().getTime()}
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setLoading(false)
                setNewState({status:'success',data:resp.resp})
              }else if(resp.err){
                setLoading(false)
                return setNewState({status:'failed',data:resp.err})
            }else{
                setLoading(false)
                return setNewState({status:'failed',data:'Something went wrong.'})
            }
        }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
    }
    fetchData(); 
    // if(note.length>0||text.length>0){
    //         const timer = setInterval(() => {
                     
    //     }, 500);
    //     return clearInterval(timer);
    // }
}
useEffect(()=>{
    if(text==='@'){
        let a = []
        lesson.participants.forEach(element => {
             a.push(users.filter(filt=>filt._id.toString()===element.toString())[0].userName)
        });
       return setWriterAuthSuggesttions(a)
    }else if(text==='@all'){
        setWriterAuthSuggesttions([])
       return setWriterAuth('all')
    }else{
         setWriterAuthSuggesttions([])
    }
    console.log('writerAuthSuggesttions',writerAuthSuggesttions)
},[text])



useEffect(()=>{
        socket.on('received message',data=>{
        setCurrentMessage(data.message.message.text);
        setCurrentNote(data.message.message.note);
        // setCount(data.count)
        // setCurrentMessage(data.message.text)
        // setCurrentNote(data.message.note)
        // let a = localStorage.getItem('received messages')?JSON.parse(localStorage.getItem('received messages')):[]
        // a.push(data)
        // localStorage.setItem('received messages',JSON.stringify(a));
        // setReceivedMessages([...receivedMessages,JSON.parse(localStorage.getItem('received messages'))]) 
        // setReceivedMessages([...receivedMessages,a])
    })
})
useEffect(() => {
    window.addEventListener("beforeunload", ()=>{
        localStorage.setItem('received message',currentMessage)
    });
    return () => {
      window.removeEventListener("beforeunload",()=>{});
    };
  },[]);

function handleStartClass(){
    setLoading(true)
   
    async function fetchData(){
        await fetch(`${url}/start-class`,{
            mode:'cors',
            method:'put',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({user,
              lessonId
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setLoading(false)
                window.location.reload()
              }else if(resp.err){
                setLoading(false)
                return setNewState({status:'failed',data:resp.err})
            }else{
                setLoading(false)
                return setNewState({status:'failed',data:'Something went wrong.'})
            }
        }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
    }
    fetchData();
}
const handleContent = (e)=>{
    socket.emit('message',{sender:user,lessonId,message:{text,note},count:count+1,time:new Date().getTime()})
    e.preventDefault()
    setMessages([...messages,{sender:user,lessonId,message:{text,note},time:new Date().getTime()}])
    setText('')
    setNote('')
   if(course){
    setLoading(true)
    async function fetchData(){
      await fetch(`${url}/lesson-content`,{
          mode:'cors',
          method:'put',
          headers:{
              'Content-Type':'application/json',
              'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
          },body:JSON.stringify({user,
            lessonId,message:{user,note,text,time:new Date().getTime()}
          })
      }).then(res=>res.json())
      .then(resp=>{
          if(resp.resp){
              setLoading(false)
              setNewState({status:'success',data:resp.resp})
            }else if(resp.err){
              setLoading(false)
              return setNewState({status:'failed',data:resp.err})
          }else{
              setLoading(false)
              return setNewState({status:'failed',data:'Something went wrong.'})
          }
      }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
  }
  fetchData();
   }
}
useEffect(()=>{
    let a = lessons?.filter(filt=>filt?._id?.toString()===lessonId?.toString())[0];
    setLesson(a)
},[lessonId]);

function editorChangeHandler({editor,data,value}){
    // value = receivedMessages;
    console.log('main one',value);
}
useEffect(()=>{
    console.log('lob',writerAuth)
},[writerAuth]);

    return (<Container className='classroom-container'>
        {/* {lesson?.started&&videoOn&&<Row>
            <Row><Col>Presenter<video controls src=''></video>
            </Col></Row>
            {!showMe&&<FaLockOpen className='video-controls' onClick={()=>setShowMe(true)}/>}
            {showMe&&<Col>Me<FaLock className='video-controls' onClick={()=>setShowMe(false)}/><video controls src=''></video></Col>}
            <Col className='video-users'>
            <Row>User 1<video controls src='' poster={poster}></video></Row>
            <Row>User 2<video controls src=''></video></Row>
            <Row>User 3<video controls src=''></video></Row>
            <Row>User 4<video controls src=''></video></Row>
            </Col>
        </Row>}
        <Row>{
    videoOn?<FaVideoSlash className='video-controls' onClick={()=>setVideoOn(false)}/>:<FaVideo className='video-controls' onClick={()=>setVideoOn(true)}/>
}
</Row> */}
        <Row>{`${lesson?.title} ${participants?.length}/${space?.maxParticipants} Participants`}</Row>
        {
            connected&&<p>{`${title.toUpperCase()} ${lesson?.started?'is in session':'is not in session'}`}</p>
        }
        <h4>{newState.status==='success'?'':newState?.data}</h4>
        
        {user?._id?.toString()===lesson?.tutor?.toString()&&<Button onClick={handleStartClass} className='lesson-actions-toggler'>{lesson?.started?'End class':'Start Class'}</Button>}
        {/* <ScrollToBottom className={ROOT_CSS}></ScrollToBottom> */}

{
   receivedMessages?.length>0&&receivedMessages?.map((message,index)=>{
        return(<Card className='card-classroom' key={index}>
            <CardBody className='chart-area'>
                <Col className={message?.sender?._id?.toLowerCase()===user?.userName?.toLowerCase()?'col-user':'col-tutor'}>
                {currentNote?.length>0&&<Card className='note-container'>
                <CardTitle className='note-title'>Note</CardTitle>
                    <CardText className='note-text'>{currentNote}</CardText>
                    <CardText className='note-text'>{message?.message?.text}</CardText>
                </Card>}
                </Col>
            </CardBody>
            </Card>)
    })
}
           {
            lesson?.started&&<>
           {lesson?.tutor?.toString()===user?._id?.toString()||user.userName===lesson?.writerAuth?
             <Form onSubmit={handleContent}>
             <FormControl
                 placeholder='Enter note here...'
                 onChange={e=>setNote(e.target.value)}
                 />
             <CodeMirror
             selection={{
                 ranges: [{
                   anchor: {ch: 2, line: 5},
                   head: {ch: 37, line: 5}
                 }],
                 focus: true // defaults false if not specified
               }}
               onSelection={(editor, data) => {}}
             value={currentMessage}
             options={{
               mode: lesson?.language,
               theme: 'material',
               lineNumbers: true
             }}
             onChange={(editor, data, value) => {
                 setText(value)
             }}
           />
                {loading?<Spinner/>:<Button type='submit'>Send</Button>}
             </Form>
            : <Form >
            <h4>{currentNote}</h4>

<CodeMirror
    selection={{
        ranges: [{
          anchor: {ch: 2, line: 5},
          head: {ch: 37, line: 5}
        }],
        focus: true // defaults false if not specified
      }}
      onSelection={(editor, data) => {}}
    value={currentMessage}
    options={{
      mode: lesson?.language,
      theme: 'material',
      lineNumbers: true
    }}
  />
            </Form>} </>}
            {
              lesson?.tutor?.toString()===user?._id?.toString()&&writerAuth.length<1&&writerAuthSuggesttions.length>0&&<Form>
                <select onChange={e=>setWriterAuth(e.target.value)}>
                <option value={writerAuthSuggesttions[0]}>select a student</option>
                    {
                        writerAuthSuggesttions.map((item,index)=>{
                            return(
                                <option value={item} key={index}>@{item}</option>
                            )
                        })
                    }
                </select>
                
            </Form>
            }
            {
              lesson?.tutor?.toString()===user?._id?.toString()&&writerAuth.length>0&&<Button onClick={handleWriterAuth}>Authorise {writerAuth}</Button>
            }
            
            </Container>);
};

Classroom.propTypes = {};

export { Classroom };