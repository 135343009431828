import { configureStore } from '@reduxjs/toolkit';
import userReducer, { loadUser } from './Slices/AuthSlice';
import usersReducer, { getUsers } from './Slices/usersSlice';
import visitorsReducer, { getVisitors } from './Slices/visitorsSlice';
import visitorReducer, { getVisitor } from './Slices/visitorSlice';
import coursesReducer, { getCourses } from './Slices/coursesSlice';
import blogsReducer, { getBlogs } from './Slices/blogsSlice';
import enrolmentsReducer, { getEnrolments } from './Slices/enrolmentsSlice';
import myCoursesReducer from './Slices/myCoursesSlice';
import { usersApi } from './UsersApi';
import spacesReducer, { getSpaces, loadSpaces } from './Slices/spacesSlice';
import subscriptionsReducer, { getSubscriptions, loadSubscriptions } from './Slices/subscriptionsSlice';
import courseReducer, { loadCourse } from './Slices/courseSlice';
import blogReducer, { loadBlog } from './Slices/blogSlice';
import contactsReducer, { getContacts } from './Slices/contactsSlice';
import lessonReducer, { loadLesson } from './Slices/lessonSlice';
import lessonsReducer, { getLessons } from './Slices/lessonsSlice';
import worksReducer, { getWorks, loadWorks } from './Slices/worksSlice';
import submissionsReducer, { getSubmissions, loadSubmission, loadSubmissions } from './Slices/submissionsSlice';
import teachingsReducer, { getTeachings } from './Slices/teachingsSlice';
import appReducer, { getApps } from './Slices/appSlice';
import { loadCommunityQuestion } from './Slices/communityQuestionSlice';
import communityQuestionsReducer, { getCommunityQuestions, loadCommunityQuestions } from './Slices/communityQuestionsSlice';
import communityCommentsReducer, { getCommunityComments, loadCommunityComments } from './Slices/communityCommentsSlice';

export const store = configureStore({
  reducer: {
    auth:userReducer,
    enrolments:enrolmentsReducer,
    users:usersReducer,
    apps:appReducer,
    visitor:visitorReducer,
    visitors:visitorsReducer,
    communityQuestions:communityQuestionsReducer,
    communityComments:communityCommentsReducer,
    blog:blogReducer,
    blogs:blogsReducer,
    lesson:lessonReducer,
    lessons:lessonsReducer,
    spaces:spacesReducer,
    works:worksReducer,
    submissions:submissionsReducer,
    subscriptions:subscriptionsReducer,
    course:courseReducer,
    courses:coursesReducer,
    myCourses:myCoursesReducer,
    contacts:contactsReducer,
    teachings:teachingsReducer,
    [usersApi.reducerPath]:usersApi.reducer
  },
  middleware:(getDefaultMiddleware)=>(
    getDefaultMiddleware().concat(usersApi.middleware)
  ),
  devTools: process.env.NODE_ENV !== 'production',
})

store.dispatch(getSubscriptions())
store.dispatch(getCourses())
store.dispatch(getLessons())
store.dispatch(getSpaces())
store.dispatch(getEnrolments())
store.dispatch(getBlogs())
store.dispatch(getCommunityQuestions())
store.dispatch(getCommunityComments())
store.dispatch(getVisitor())
store.dispatch(getVisitors())
store.dispatch(getContacts())
store.dispatch(getUsers())
store.dispatch(getApps())
store.dispatch(getTeachings())
// store.dispatch(getWorks())
// store.dispatch(getSubmissions())

//Loaders
store.dispatch(loadUser())
store.dispatch(loadCourse())
store.dispatch(loadSpaces())
store.dispatch(loadSubscriptions())
store.dispatch(loadLesson())
store.dispatch(loadWorks())
store.dispatch(loadSubmissions())
store.dispatch(loadCommunityQuestion())
store.dispatch(loadCommunityQuestions())
store.dispatch(loadCommunityComments())