import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import NavBar from './Parts/Nav';
import { Courses } from './Components/Pages/Courses';
import { Course } from './Components/Pages/Course';
import Confirmation from './Components/Pages/User/Confirmation';
import { useDispatch, useSelector } from 'react-redux';
import { PasswordChange } from './Components/Pages/User/PasswordChange';
import { Login } from './Components/Pages/User/Login';
import { Profile } from './Components/Pages/User/Profile';
import { Register } from './Components/Pages/User/Register';
import Payer from './Components/Pages/googlePAY';
import { PaymentSuccess } from './Components/Pages/PaymentSuccess';
import { PaymentGateWay } from './Components/Pages/PaymentGateWay';
import { MailDelivery } from './Components/Pages/User/MailDelivery';
import { Contact } from './Components/Pages/User/Contact';
import { Dashboard } from './Components/Pages/User/Dashboard';
import { ChangePassword } from './Components/Pages/User/ChangePassword';
import { ResetPassword } from './Components/Pages/User/PasswordReset';
import { PasswordSent } from './Components/Pages/User/PasswordSent';
import { Blog } from './Components/Pages/BLog/Blog';
import Workbook from './Components/Pages/BLog/Workbook';
import Editor from './Components/Pages/BLog/Editor';
import { Classroom } from './Components/Pages/Venue/Classroom';
import { Codes } from './Components/Pages/Venue/Codes';
import Coders from './Components/Pages/BLog/Coders';
import { Spaces } from './Components/Pages/Spaces';
import { SpaceDetail } from './Components/Pages/SpaceDetail';
import { WorkDetail } from './Components/Pages/User/WorkDetail';
import { Works } from './Components/Pages/User/Works';
import { TeachingRequest } from './Components/Pages/User/TeachingRequest';
import { Home } from './Home';
import Footer from './Parts/Footer';
import Faq from './Pages/Faq';
import Learn from './Pages/Learn';
import AppSetting from './AppSetup';
import { VideoCall } from './Components/Pages/Venue/VideoCall';
import PayStack from './Paystack';
import { Community } from './Community';
import { CommunityComments } from './CommunityComment';
import { getUser, logOut } from './Redux/Slices/AuthSlice';
import { useEffect } from 'react';

//Include speech to text in the classroom.

function App() {
  const user = useSelector(state=>state.auth.user);
const dispatch = useDispatch()
const token = JSON.parse(localStorage.getItem('token'))
useEffect(()=>{
  if(!user&&token){
    dispatch(getUser({token:token}))
  }else if(user===undefined&&token===undefined){
    dispatch(logOut())
  }
},[token])

  return (
  <BrowserRouter>
    <NavBar/>
  <Routes>
  <Route path='/' element={<Home/>} />
  <Route path='/:token' element={user?<Navigate replace to = '/'/>:<Home />}/>
    <Route path='/courses' element={<Courses/>} />
    <Route path='/course/:courseId' element={<Course/>} />
    <Route path='/:userId/profile' element={!user?<Navigate replace to = '/login'/>:<Profile />} />
    <Route path='/setting' element={!user?<Navigate replace to = '/login'/>:<AppSetting />} />
    <Route path='/dashboard' element={!user?.isAdmin?<Navigate replace to = '/login'/>:<Dashboard />} />
    <Route path = '/:token/:email/authentication' element={user?<Navigate replace to = {`/${user?._id}/profile`}/>:<Confirmation />}/>
    <Route path='/login' element={user?<Navigate replace to = {`/${user?._id}/profile`}/>:<Login />} />
    <Route path='/:email/mail-delivery' element={<MailDelivery/>} />
    <Route path='/:email/password-sent' element={<PasswordSent/>} />
    <Route path = '/:email/:token/password-reset' element={<ResetPassword />} />
    <Route path='/password-change' element={<ChangePassword />} />
    <Route path='/register' element={user?<Navigate replace to = {`/${user?._id}/profile`}/>:<Register />} />
    <Route path='/payment-success/:category/:courseId' element={!user?<Navigate replace to = '/login'/>:<PaymentSuccess />} />
    <Route path='/payment-gateway/:category/:courseId' element={!user?<Navigate replace to = '/login'/>:<PaymentGateWay />} />
    <Route path='/blog/:blogCourse/:blogId' element={<Blog />} />
    <Route path='/blog/:blogCourse' element={<Blog />} />
    <Route path='/blog' element={<Blog />} />
    <Route path='/workbook/:type' element={<Workbook/>} />
    <Route path='/workbook/:type/:userId' element={!user?<Navigate replace to = '/login'/>:<Workbook />} />
    <Route path='/event-spaces' element={<Spaces />} />
    <Route path='/event-space/:spaceId' element={<SpaceDetail />} />
    <Route path='/payment-area/:paymentType/:company/:itemId' element={<PayStack />} />
    <Route path='/works/:lessonId' element={<Works />} />
    <Route path='/work/:workId' element={!user?<Navigate replace to = '/login'/>:<WorkDetail />} />
    <Route path='/contact-us' element={<Contact />} />
    <Route path='/teaching-request' element={<TeachingRequest />} />
    <Route path='/faq' element={<Faq />} />
    <Route path='/learn' element={<Learn />} />
    <Route path='/video-call' element={<VideoCall />} />
    <Route path='/community/:blogId' element={<Community />} />
    <Route path='/:blogId/:questionId' element={<CommunityComments />} />
    <Route path='/lessons/classroom/:title/:lessonId' element={!user?<Navigate replace to = '/login'/>:<Classroom />} />
  </Routes>
  <div style={{marginTop:'50px'}}>
<Footer/>
</div>
  </BrowserRouter>
  );
}

export default App;