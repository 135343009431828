import React, {useEffect} from 'react';
import './Home.css'
import headerImg from './Components/header-img.PNG'
import teachYourself from './Components/teach_yourself.PNG'
import workbookImg from './Components/workbook.PNG'
import studentPageImg from './Components/student_page.PNG'
import tutorPageImg from './Components/tutor_page.PNG'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import { FaAccessibleIcon, FaUsers, FaVideo, FaWallet } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from './Redux/Slices/AuthSlice';
import { useParams } from 'react-router-dom';

const Home = () => {
    const user = useSelector(state=>state.auth.user);
    const{token} = useParams()
    const dispatch = useDispatch()
      useEffect(()=>{
        if(!user&&token){dispatch(getUser({token:token}))}
      },[token])

return (<div className='home-main-container'>
    <div className='home-main-header'>
    <div className='home-main-header-inner-wrapper'>
    <div className='home-main-header-left'>
        <h1 className='home-main-title'>Are you tired of paying to host an online event?<br/> We've got you covered!</h1>
        <p className='home-main-body'>You can now host events like meetings, presentations, coding classes with live and editable codes. Yes! The codes your tutor types is rendered on your computer live and can be edited and copied.</p>
        <div className='home-main-action-section'>
        {user?<a href={`/${user?._id}/profile`} className='home-header-btn'>
                        Get Started
                    </a>:<a href={`/login`} className='home-header-btn'>
                        Get Started
                    </a>}
        </div>
        </div>
    <div className='home-main-header-right'/>
    </div>
</div>
<Container className='home-main-cards-wrapper'>
<Row className='home-main-cards'>
<Col><Card className='home-main-card'>
        <FaVideo className='home-card-icon'/>
        <h1 className='home-card-title'>Video Calls <em>Coming soon</em></h1>
        <p className='home-card-body'>All participants can be seen, interacted with in both voice and video calls.</p></Card></Col>
<Col><Card className='home-main-card'>
        <FaAccessibleIcon className='home-card-icon'/>
        <h1 className='home-card-title'>Usability</h1>
        <p className='home-card-body'>You don't have to worry about difficulty in getting what the presenter/tutor types or writes as you get the same thing live and editable on your device.</p></Card></Col>
        <Col><Card className='home-main-card'>
        <FaUsers className='home-card-icon'/>
        <h1 className='home-card-title'>Crowd Limitations</h1>
        <p className='home-card-body'>You can host as many as 5000 participants in an event without server interruptions.</p></Card></Col>
    <Col><Card className='home-main-card'>
        <FaWallet className='home-card-icon'/>
        <h1 className='home-card-title'>Affordability</h1>
        <p className='home-card-body'>You can don't have to save up much to host events, you can host free events on our Basic plan.</p></Card></Col>
</Row>
<Row className='about-us-section' xl={12}>
<Col>
<Image src={headerImg} className='about-us-section-img'/>
</Col>
<Col>
<Card.Title className='about-title'>About Us</Card.Title>
<Card.Body className='about-title-bold'>All your online live event needs!</Card.Body>
<Card.Text className='about-body-text'>
    Retiny Event Space - RES is the product of the Retiny Technology Limited, and it is used to make online events accessable to all online users who wish host events like board meetings, seminars, presentations and coding classes where there is need to share live and editable codes with the students.
    Individuals as well as companies can create courses, get a space here on event retiny space, and host their participants easily without breaking the bank.
</Card.Text>
</Col>
</Row>
<div className='sample-section'>
<div className='sample-item'>
<Card.Title className='about-title'>Tutor's Device</Card.Title>
<Card.Text className='about-body-text'>As a tutor, I type my codes here while teaching my students.</Card.Text>
<Image src={tutorPageImg} className='home-content-image'/>
</div>
<div className='sample-item'>
<Card.Title className='about-title'>Student's Device</Card.Title>
<Card.Text className='about-body-text'>As a student, I have the same code here on my device and I can edit and copy it.</Card.Text>
<Image src={studentPageImg} className='home-content-image'/>
</div>
<div className='sample-item'>
<Card.Title className='about-title'>Teach Yourself</Card.Title>
<Card.Text className='about-body-text'>You can learn coding here on your own without the need of a tutor anytime for free. Each topic treated has a number of examples to help the learner understand what is being treated better.</Card.Text>
<Image src={teachYourself} className='home-content-image'/>
</div>
<div className='sample-item'>    
<Card.Title className='about-title'>Live Code Editor With CodeMarkdown</Card.Title>
<Card.Text className='about-body-text'>While learning on your own, you can run your codes and see your result.</Card.Text>
<Image src={workbookImg}  className='home-content-image'/>
</div>
</div>
</Container>
    </div>);
};

Home.propTypes = {};

export { Home };