import React, { useState } from 'react';
import {useSelector} from 'react-redux'
import './Learn.css'
import { LearnContent } from './jsonContent';
import { url } from '../Redux/Api';
import { Spinner } from 'react-bootstrap';

const Learn = () => {
    const[email, setEmail] = useState('')
    const[subscriptionSuccess, setSubscriptionSuccess] = useState('')
    const[subscriptionError, setSubscriptionError] = useState('')
    const[subscriptionLoading, setSubscriptionLoading] = useState(false)
    const user = useSelector(state=>state.auth.user)

const app = useSelector(state=>state.apps.items)
const handleSubmit = e=>{
    e.preventDefault()
    setSubscriptionLoading(true)
    async function fetchData(){
await fetch(`${url}/newsletter-subsrciption`,{
    method:'post',
    headers:{
        'Content-Type':'application/json',
        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
    },
    body:JSON.stringify({
        email,appId:app?._id,user
    })
}).then(res=>res.json())
.then(resp=>{
    if(resp.response){
        setSubscriptionSuccess(resp.response)
        setSubscriptionLoading(false)
    }else if (resp.err){
        setSubscriptionError(resp.err)
        setSubscriptionLoading(false)
    }
}).catch(err=>{
    if(err){
        setSubscriptionError(err.message)
        setSubscriptionLoading(false)
    }
})
    }
    fetchData()
}
    
    return (
        <div className='learn-main'>
                <div  className='learn-left'>
                    <h5 className='learn-left-section-title'>Learn how retiny space works</h5>
                    {
                        LearnContent.map((item,index)=>{
                            return(<div key={index} className='learn-left-content-items'>
                                <h6 className='learn-title'>{item.title}</h6>
                                <p className='learn-body'>{item.body==='Kindly click'?<>Kindly click <a href='/event-spaces'>here</a> and choose the plan best suitable to your needs.</>:item?.body}</p></div>)
                        })
                    }
                </div>
                <div className='learn-right'>
                    <form onSubmit={handleSubmit} className='newsletter-form'>
                        {
                            subscriptionSuccess!==''?<p className='subscription-success'>{subscriptionSuccess}</p>:
                            subscriptionError!==''?<p className='subscription-error'>{subscriptionError}</p>:<p className='newsletter-form-title'>Don't miss an update</p>
                        }
                        <input type='email' placeholder='Enter email' onChange={e=>setEmail(e.target.value)} className='newsletter-input' />
                        {
                            subscriptionLoading?
                            <Spinner />// <FaSpinner className='learn-image-loading' />
                            :<button type='submit' className='newsletter-btn'>Ok</button>
                            }
                    </form>
                </div>
        </div>
    );
};

export default Learn;