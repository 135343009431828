import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
// import { spaces } from '../../assets/JSON';
import { Anchor, Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Spaces.css';


const Spaces = props => {
const spaces = useSelector(state=>state.spaces.items);
const navigate = useNavigate();

    return(<div className='spaces-container'>
  {spaces?.length>0?
    <>
<h4 className="space-title">Get An Event Space</h4>
<Row xl={12} md={12} style={{display:'flex',alignItems:'center'}} className='spaces'>
{
   spaces?.map((item,index)=>{
    return(
<Col key={index}>
<Card className={item?.spaceCategory==='Silver'?'card-custom-display':'card-display'}>
<CardBody className="card-body" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
  <CardTitle className={item?.spaceCategory==='Silver'?'card-title-custom':'card-title'} style={{textTransform:'capitalize'}}>{item?.spaceCategory}</CardTitle>
  <CardText className={item?.spaceCategory==='Gold'?"card-text-spaces-custom":"card-text-spaces"}>{item?.description}</CardText>
  <CardTitle className="card-text-title">${item?.spaceCost}/month</CardTitle>
  <CardText className="card-text-spaces">Live code editing</CardText>
  <CardText className="card-text-spaces">Tutor-student interaction</CardText>
  <CardText className="card-text-spaces">Class Work/Assignment</CardText>
  <CardText className="card-text-spaces">Class Video Calls -coming soon.</CardText>
  <CardText className="card-text-spaces">Up to {item?.maxParticipants} attendees</CardText>
  <Button onClick={()=>navigate(`/event-space/${item?._id}`)} className='btn checkout-btn'>Get started</Button>
</CardBody>
</Card>
</Col>
)

})
}
</Row>
    </>
  :
  <Row>
  <Container className='container'>
    <div className='page404'>
    <h4 className="title404">Loading content...</h4>
    {/* <p className='content404'>No content found for your search</p> */}
    </div>
    </Container>
</Row>}
</div>)
};

Spaces.propTypes = {};

export { Spaces };