import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url } from "../Api";

const initialState = {
    items:localStorage.getItem('teachings')?JSON.parse(localStorage.getItem('teachings')):[],
    status:''
}
export const getTeachings = createAsyncThunk(
    'teachings/getTeachings',
    async()=>{
        try {
          const response = await axios.get(`${url}/teachings`)  
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)

const teachingsSlice = createSlice({
    name:'teachings',
    initialState,
    reducers:{
        loadTeachings(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:builder=>{
        builder.addCase(getTeachings.pending,(state,action)=>{
            return {...state,status:'pending'}
        })
        builder.addCase(getTeachings.rejected,(state,action)=>{
            return {...state, status:'rejected'}
        })
        builder.addCase(getTeachings.fulfilled,(state,action)=>{
            return {...state,items:action.payload,status:'fulfilled'}
        })
    }
})

export const{loadTeachings} = teachingsSlice.actions;
export default teachingsSlice.reducer;