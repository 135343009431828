import React, { useEffect, useState } from 'react';
import { usePaystackPayment } from 'react-paystack';
import './PayStack.css';
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from 'react-redux';
import { Container, Spinner } from 'react-bootstrap';
import { publicKey, url } from './Redux/Api';

const PayStack = () => {
const{company,paymentType,itemId,category} = useParams();
const user = useSelector(state=>state.auth.user)
const[loading, setLoading] = useState(false);
const[newState, setNewState] = useState({status:'',data:''});
const spaces = useSelector(state=>state.spaces.items);
const[space, setSpace] = useState({});
const courses = useSelector(state=>state.courses.items);
const[course, setCourse] = useState({});
useEffect(()=>{
    if(spaces?.length>0){
      let a = spaces?.filter(filt=>filt._id.toString()===itemId.toString());
    setSpace(a[0]);
    }
},[itemId]);

useEffect(()=>{
  if(courses?.length>0){
    let a = courses?.filter(filt=>filt._id.toString()===itemId.toString());
  setCourse(a[0]);
  }
},[itemId]);

const navigate = useNavigate()
const config = {
    reference: (new Date()).getTime().toString(),
    email:user?.email,
    amount:space?space?.spaceCost*100:course?.cost*100,
    phone:user?.phone,
    publicKey: publicKey
};

const handleSub = ()=>{
  setLoading(true)
    if(paymentType==='course'){
      setLoading(true)
      async function fetchData(){
          await fetch(`${url}/course-registration/${itemId}/${category}`,{
              mode:'cors',
              method:'post',
              headers:{
                  'Content-Type':'application/json',
                  'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
              },body:JSON.stringify({user})
          }).then(res=>res.json())
          .then(resp=>{
              if(resp.resp){
                setLoading(false)
                   setNewState({status:'success',data:resp.resp})
                   return navigate(`/payment-success/${category}/${itemId}`)
              }else if(resp.err){
                setLoading(false)
                  return setNewState({status:'failed',data:resp.err})
              }else{
                setLoading(false)
                  return setNewState({status:'failed',data:'Something went wrong.'})
              }
          }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
      }
      fetchData();
    
    }else if(paymentType==='space'){
      async function fetchData(){
        await fetch(`${url}/create-subscription`,{
            mode:'cors',
            method:'post',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({
                spaceId:itemId,company,spaceCost:space.spaceCost,user
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
              setLoading(false)
                navigate(`/${user?._id}/profile`);
                return setNewState({status:'success',data:resp.resp})
            }else if(resp.err){
                setLoading(false)
                return setNewState({status:'failed',data:resp.err})
            }else{
                setLoading(false)
                return setNewState({status:'failed',data:'Something went wrong.'})
            }
        }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
    }
    fetchData();
    }
}

const onSuccess = () => {
  handleSub()
};
const onClose = ()=>{
    setNewState({data:'Transaction cancelled',status:'failed'});
}
const PaystackHookComponent = () => {
  const initializePayment = usePaystackPayment(config);

  return (
    <div className='paystack-main-container'>
      {newState.data?.length>0?newState.data:<span className='pay-title'>Payment for {space?.spaceCategory?space?.spaceCategory?.toUpperCase():course?.title?.toUpperCase()}</span>}
        <div className='horizontal-line'></div>
      <div className='pay-wrapper'><span className='pay-span'>Amount</span><span className='pay-detail'>{space?space?.spaceCost:course?.cost}</span></div>
      <div className='pay-wrapper'><span className='pay-span'>Product</span><span className='pay-detail'>{space?space?.spaceCategory:course?.title}</span></div>
        <div className='pay-btn-wrapper'>
          {loading?<Spinner/>:<button 
        onClick={() => {initializePayment(onSuccess, onClose)}}
             className='pay-btn'>Pay</button>}
             </div>
    </div>
  );
};

return (
  <Container className='paystack-container'>
    <PaystackHookComponent />
    </Container>
  );
}

export default PayStack;