import React, {useEffect, useState} from 'react';
import GooglePayButton from '@google-pay/button-react'
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Card, CardText, Container, ModalBody, Spinner } from 'react-bootstrap';
import { url } from '../../Redux/Api';
import './Courses.css'

const PaymentGateWay = props => {
const{category,company,courseId} = useParams();
const course = useSelector(stata=>stata.course.course);
const[newState, setNewState] = useState({status:'',data:''});
const[loading, setLoading] = useState(false);

const navigate = useNavigate();
const handleReg = ()=>{
  return navigate(`/payment-area/course/${company}/${course?._id}`)
}

return <Container className='payment-gateway'>
        <Card className='payment-card'>

<div>
  <CardText style={{textAlign:'center',color:'var(--danger-1)',padding:'10px'}}>{newState.data}</CardText>
<p style={{textAlign:'center',fontWeight:'bold'}}>Pay the sum of ${course?.cost} for {course.title} {category} program.</p>
<p style={{textAlign:'center'}}>Click "Continue" if you want to make payment.</p>
      </div>
      {loading?<Spinner/>:<ButtonGroup className="btn-group">
        <Button className='payment-gateway-btn-cancel' style={{borderRadius:'4px'}} onClick={()=>navigate(-1)}>Cancel</Button>
        <Button className='payment-gateway-btn-proceed' style={{borderRadius:'4px'}} onClick={handleReg}>Continue</Button>
        <GooglePayButton
  environment="TEST"
  paymentRequest={{
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['MASTERCARD', 'VISA'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'example',
            gatewayMerchantId: 'exampleGatewayMerchantId',
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: '12345678901234567890',
      merchantName: 'Retiny Technology Ltd',
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: course?.price,
      currencyCode: 'USD',
      countryCode: 'US',
    },
  }}
  onLoadPaymentData={paymentRequest => {
    console.log('load payment data', paymentRequest);
    // handleReg()
  }}
/>
      </ButtonGroup>}
</Card>
    </Container>;
};

PaymentGateWay.propTypes = {};

export { PaymentGateWay };